import { useContext, useRef, useEffect } from 'react'
import { Typography, Button, TextInput, StackView, Box, Card, useResponsiveProp } from '@telus-uds/components-web'
import { PageSection } from '@telus/koodo-shared-components'
import { SiteBuilderContext } from '@/siteBuilder/renderer/context/SiteBuilderContext'

const CommunitySearch = ({ topText, bottomText, placeholderText, cta }) => {
  const { locale } = useContext(SiteBuilderContext)
  const isMobile = useResponsiveProp({ xs: true, md: false })
  const searchInputRef = useRef<HTMLInputElement>(null)

  const getSearchUrl = () => {
    if (searchInputRef?.current?.value) {
      return locale.language === 'fr'
        ? encodeURI(`https://communaute.koodomobile.com/search?q=${searchInputRef.current.value}`)
        : encodeURI(`https://community.koodomobile.com/search?q=${searchInputRef.current.value}`)
    }
    return '#?'
  }

  const handleOnClick = () => {
    window.location.href = getSearchUrl()
    // TODO: implement Analytics as needed
  }

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      window.location.href = getSearchUrl()
    }
  }

  useEffect(() => {
    if (searchInputRef?.current) searchInputRef.current.addEventListener('keydown', (e) => handleKeyDown(e))

    return () => {
      if (searchInputRef?.current) searchInputRef.current.removeEventListener('keydown', handleKeyDown)
    }
  }, [searchInputRef])

  const container: JSX.Element = (
    <>
      <div>
        <Typography block heading="h3" variant={{ size: 'h3' }}>
          {topText}
        </Typography>
        <Typography block heading="h3" variant={{ size: 'h3' }}>
          {bottomText}
        </Typography>
      </div>
      <Box space={0} flex={1}>
        <TextInput initialValue={placeholderText} ref={searchInputRef} />
      </Box>
      <Button variant={{ width: 'responsive' }} onPress={handleOnClick}>
        {cta ?? 'Submit'}
      </Button>
    </>
  )

  return (
    <PageSection id="communitySearchOne">
      <Card
        variant={{ background: 'grid', padding: 'custom' }}
        tokens={{
          paddingBottom: 24,
          paddingLeft: 24,
          paddingRight: 24,
          paddingTop: 24,
        }}
      >
        {isMobile ? (
          <StackView direction={'column'} space={3}>
            {container}
          </StackView>
        ) : (
          <StackView direction={'row'} space={5} tokens={{ alignItems: 'center' }}>
            {container}
          </StackView>
        )}
      </Card>
    </PageSection>
  )
}

export default CommunitySearch
