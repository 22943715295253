import { ICommunitySearchFields } from 'contentful-types'

import { withContentful } from 'src/siteBuilder/renderer/contentful/withContentful'

import CommunitySearch from './CommunitySearch'

const ContentfulCommunitySearch = withContentful<ICommunitySearchFields>(CommunitySearch, {
  topText: (props) => props.fields?.topText,
  bottomText: (props) => props.fields?.bottomText,
  placeHolderText: (props) => props.fields?.placeholderText,
  cta: (props) => props.fields?.cta,
})

export default ContentfulCommunitySearch
